import TenantBar from './TenantBar.jsx'
import MenuButton from './MenuButton.jsx'
import UserBar from './UserBar.jsx'
import NotificationBar from './NotificationBar.jsx'
import { Link } from 'react-router-dom'

const HeaderNavBar = ({openMenu,setOpenMenu,companiesList,branchesList,tenantUsername,tenantImage,tenant,companySelected,branchSelected}) => {
    
    return (
        <>
                <div className=' w-[100%] ms-auto flex justify-between items-center bg-[#251658]  sticky top-0 z-50 text-white  '>
            <div className="px-3 py-1 m-auto  w-full  px-1  flex justify-between items-center ">
                <div className='flex flex-col md:flex-row  w-full  justify-between items-center '>
                    <div className='flex items-center justify-between w-full '>
                        {/* <MenuButton openMenu = {openMenu}  setOpenMenu = {setOpenMenu} /> */}
                        <TenantBar 
                            companiesList = {companiesList}
                            branchesList = {branchesList}
                            companySelected={companySelected} 
                            branchSelected={branchSelected} />
                    </div>
                        <Link to="/" className='w-[100%] flex  '  >
                    <img className='w-28 md:w-32 m-auto' src='/logo-white-ar.webp'  />
                        </Link>
                    <UserBar
                        tenantUsername = {tenantUsername}
                        tenantImage = {tenantImage}
                        tenant = {tenant}
                    />
                </div> 
            </div>
            </div>
        </>
    )
}

export default  HeaderNavBar 