import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import BasicSelector from '../components/ui/BasicSelector.jsx';
import { setCurrentCompany } from '../store/currentCompanySlice.js';
import { setCurrentBranch } from '../store/currentBranchSlice.js';

const TenantBar = ({ companiesList, branchesList, companySelected, branchSelected }) => {
  const dispatch = useDispatch();
  const [openListCompanies, setOpenListCompanies] = useState(false);
  const [openListBranches, setOpenListBranches] = useState(false);

  const onSelectedCompany = (company) => {
    dispatch(setCurrentCompany({ name: company.label, value: company.value }));
  };

  const onSelectedBranch = (branch) => {
    dispatch(setCurrentBranch({ name: branch.label, value: branch.value }));
  };

  return (
    <div className="flex   flex-wrap md:flex-nowrap justify-between items-center text-xs ms-4 gap-0 w-[100%]">
      {/* اختيار الشركة */}
      <BasicSelector
        border={false}
        label={companySelected.name}
        defaultValue={companySelected.name}
        listItem={companiesList}
        openList={openListCompanies}
        setOpenList={setOpenListCompanies}
        onSelected={onSelectedCompany}
      >
        <img className="w-5" src="/assets/icons/company-svgrepo-com 1.png" alt="Company" />
      </BasicSelector>

      <div className="text-slate-200 hidden md:flex">/</div>

      {/* اختيار الفرع */}
      <BasicSelector
        border={false}
        label={branchSelected.name}
        defaultValue={branchSelected.name}
        listItem={branchesList}
        openList={openListBranches}
        setOpenList={setOpenListBranches}
        onSelected={onSelectedBranch}
      >
        <img className="w-5" src="/assets/icons/location.png" alt="Branch" />
      </BasicSelector>
    </div>
  );
};

export default TenantBar;
