import { Routes, Route } from "react-router-dom";
import withTenant from "./utils/withTenant";
import { useState } from "react";
import { useSelector } from "react-redux";

import NotFound from "./pages/NotFound";
import TokenRedirect from "./pages/TokenRedirect";
import Logout from "./pages/Logout";
import DashBoard from "./pages/DashBoard";

import HeaderNavBar from "./layout/HeaderNavBar";
import Footer from "./layout/Footer";
import MenuSide from "./layout/MenuSide";

import withGuard from "./utils/withGuard";
import { useTenant, useStartup } from "./hooks";
import { MwSpinner } from "./components/ui";
import "./index.css";
import Settings from "./pages/Settings";
import NotificationBar from "./layout/NotificationBar";

function App({ isShowLicense }) {
  const [openMenu, setOpenMenu] = useState(false);
  const langState = useSelector((state) => state.lang);
  const {
    tenant,
    tenantUsername,
    loading: loadingTenant,
    companiesList,
    branchesList,
    tenantId,
    companySelected,
    branchSelected,
  } = useTenant();
  const { startup } = useStartup();
  return !loadingTenant && startup ? (
    <div
      dir={langState?.value === "ar" ? "rtl" : "ltr"}
      className="h-[100vh] flex flex-col  overflow-scroll md:overflow-hidden"
    >
      <div className="bg-[#251658] flex flex-col flex-1 w-fullz">
        <NotificationBar />
        <HeaderNavBar
          companySelected={companySelected}
          branchSelected={branchSelected}
          companiesList={companiesList}
          branchesList={branchesList}
          tenantUsername={tenantUsername}
          tenant={tenant}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
        />
        {/* القائمة الجانبية */}
        <MenuSide openMenu={openMenu} setOpenMenu={setOpenMenu} />
        {/* منطقة المحتوى الأبيض */}
        <div
          className={`flex flex-col md:w-[96.5%]  ms-auto  w-[90%] flex-1 p-1 ${
            openMenu ? "md:ms-10" : "md:ms-10"
          }`}
        >
          <div className="rounded-2xl bg-white border border-white border-[5px] h-full overflow-scroll flex-grow ">
            <Routes>
              <Route path="/" element={<DashBoard />} />
              <Route path=":tenant/" element={<DashBoard />} />
              <Route path=":tenant/dashboard" element={<DashBoard />} />
              <Route path=":tenant/token/:token" element={<TokenRedirect />} />
              <Route path=":tenant/logout" element={<Logout />} />
              <Route path=":tenant/settings" element={<Settings />} />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>

      <footer className="w-full bg-[#251658] text-white py-4 text-center">
        <Footer />
      </footer>
    </div>
  ) : (
    <MwSpinner />
  );
}

export default withTenant(withGuard(App));
