export const constAPPS = [
  {
    name: "sales",
    icon: "salles.png",
  },
  {
    name: "purchases",
    icon: "buy.png",
  },
  {
    name: "hr",
    icon: "employee.png",
  },
  {
    name: "rating",
    icon: "rate.png",
  },
  {
    name: "store",
    icon: "eco.png",
  },
  {
    name: "assets" || "finance" || 'Finance',
    icon: "icons8-asset-100.png",
  },
  {
    name: "financial",
    icon: "accounts.png",
  },
  {
    name: "production",
    icon: "lines.png",
  },
  {
    name: "pos",
    icon: "points.png",
  },
  {
    name: "stocks",
    icon: "container.png",
  },
];
