import { useEffect, useState } from "react";
import usePost from "./usePost";

const useUploadFile = (allowTypes = "", path = "", type = "file") => {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    data: resultUploads,
    loading: loadingUpload,
    postData: upload,
  } = usePost(false);
  const {
    data: resultdelete,
    loading: loadingDeleting,
    postData: deleteItem,
  } = usePost(false);
  const [isMulti, setIsMulti] = useState(false);
  const [multiFileLength, setMultiFileLength] = useState(0);

  const handleDelete = (keyFile) => {
    const _data = {
      keyFile: keyFile,
    };
    deleteItem(`${process.env.REACT_APP_UPLOADS_API_BASE_URL}/delete`, _data);
  };
  const handleUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0] || null;
    console.log(file);
    if (file) {
      if (allowTypes.find((type) => type === file.type)) {
        // if(type === 'image'){
        //     setLocation(URL.createObjectURL(file));
        // }
        const fileName = file.name;
        try {
          console.log("file => ", file);
          const _formData = new FormData();
          _formData.append("myfile", file, fileName);
          upload(
            `${process.env.REACT_APP_UPLOADS_API_BASE_URL}/${path}`,
            _formData
          );
          setError("")
        } catch (error) {
          setLoading(false);
          setError(error)
        }
      } else{
        setLoading(false);
        console.log("error image type:", file.type);
        setError("error")
      }
    }
  };

  const handleMultiUploads = async (event) => {
    setIsMulti(true);
    console.log(event.target.files);
    setLoading(true);
    setMultiFileLength(Object.values(event.target.files).length);
    Object.values(event.target.files).forEach((file, index) => {
      console.log(file);
      if (file) {
        if (allowTypes.find((type) => type === file.type)) {
          const fileName = file.name;
          try {
            const _formData = new FormData();
            _formData.append("myfile", file, fileName);
            upload(
              `${process.env.REACT_APP_UPLOADS_API_BASE_URL}/${path}`,
              _formData
            );
            setTimeout(() => {
              console.log(`Uploading ${index} =>`, file);
            }, 5000);
          } catch (error) {
            setLoading(false);
            window.alert("Error uploading image:", error);
          }
        } else {
          setLoading(false);
          window.alert("error image type:", file.type);
          window.alert("Error uploading image:", error);
          
        }
      }
    });
  };

  useEffect(() => {
    if (resultUploads && !isMulti) {
      setLoading(false);
      setLocation(resultUploads.data.location);
      console.log("Location =>", resultUploads.data.location);
    }
    if (resultUploads && isMulti) {
      console.log("Multi Upload", resultUploads.data.location);
      setLocation(resultUploads.data.location);
      setMultiFileLength((state) => state - 1);
      if (multiFileLength === 1) {
        setLoading(false);
      }
    }
  }, [resultUploads]);

  return {
    location,
    loading,
    error,
    handleUpload,
    handleMultiUploads,
    resultdelete,
    loadingDeleting,
    handleDelete,
  };
};

export default useUploadFile;
