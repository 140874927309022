import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import BasicSelector from "../components/ui/BasicSelector.jsx";
import { useSelector, useDispatch } from "react-redux";
import { setLang } from "../store/langSlice.js";
import { useUser } from "../hooks/";

// ICONS
import { MdLanguage } from "react-icons/md";
import { VscAccount } from "react-icons/vsc";
import { MdNotificationsNone, MdSupport } from "react-icons/md";

const UserBar = ({ tenantUsername,tenantImage ,tenant }) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getUser } = useUser();
  const _token = localStorage.getItem("token");

  // إعداد قائمة اللغات
  const [openListLang, setOpenListLang] = useState(false);
  const listLangHandler = tenant?.languages?.map(language => ({
    label: language.langName,
    value: language.langCode,
  })) || [];
  const langSelected = useSelector((state) => state.lang);

  const logoutHandler = () => {
    console.log("logout");
    navigate(`/${tenantUsername}/logout`);
  };

  const onSelectedLangHandler = (lang) => {
    i18n.changeLanguage(lang.value);
    dispatch(setLang({ label: lang.label, value: lang.value }));
  };

  const onSelectedUserList = (item) => {
    switch (item.value) {
      case "Logout":
        logoutHandler();
        break;
      default:
        break;
    }
  };

  // إعداد قائمة خيارات المستخدم
  const [openListUser, setOpenListUser] = useState(false);
  const userList = [
    { label: "My Account", value: "MyAccount" },
    { label: "Change Password", value: "ChangePassword" },
    { label: "Logout", value: "Logout" },
  ];

  return (
    <div className="flex flex-wrap items-center justify-between gap-1 text-xs md:justify-end md:me-[-21px] w-full px-2">
      <BasicSelector
        border={false}
        listItem={userList}
        label={getUser?.name}
        defaultValue={getUser?.name}
        openList={openListUser}
        setOpenList={setOpenListUser}
        withChangeLabel={false}
        onSelected={onSelectedUserList}
      >
        {tenantImage ?
        <img
        className="w-5 me-3 h-5"
        src={tenantImage}
        alt="User Avatar"
        />
        :
        <img
        className="w-5 me-1 h-5 "
        src="/assets/icons/avatar-3637425_640.png"
        alt="User Avatar"
        />
      }
      </BasicSelector>

      <img
        className="w-4 hover:w-[18.5px]  cursor-pointer transition-[0.7s]"
        src="/assets/icons/notification.png"
        alt="Notifications"
      />

      <Link
        className="px-1"
        target="_blank"
        to={`https://support.absat.org/${tenantUsername}/token/${_token}`}
      >
        <img className="w-5 hover:w-[21.5px] transition-[0.7s]" src="/assets/icons/ (4).png" alt="Help" />
      </Link>

      {/* رابط الإعدادات */}
      <Link className="px-1" to={`:tenant/settings`}>
        <img className="w-5 hover:w-[21.5px] transition-[0.7s]" src="/assets/icons/settings.png" alt="Settings" />
      </Link>

      <BasicSelector
        border={false}
        label={langSelected.label}
        openList={openListLang}
        setOpenList={setOpenListLang}
        onSelected={onSelectedLangHandler}
        defaultValue={langSelected.value}
        listItem={listLangHandler}
      >
        <img className="w-4 me-2 " src="/assets/icons/ (18).png" alt="Language" />
      </BasicSelector>
    </div>
  );
};

export default UserBar;
