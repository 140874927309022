import React from "react";
import { useTranslation } from "react-i18next";
import { useLang, useTenant } from "../hooks";
import { NavLink } from "react-router-dom";
import { sidebaricons } from "../constants/sidebaricons";

const MenuSide = ({ openMenu, setOpenMenu }) => {
  const [t] = useTranslation("global");
  const { tenantUsername } = useTenant();
  const _token = localStorage.getItem("token");
  const { langSelected } = useLang();
  const tenant = JSON.parse(localStorage.getItem("tenant")) || {};

  const sideicon =
    tenant.apps && tenant.apps.length > 0
      ? tenant.apps.map((app) => {
          const sidebarApp = sidebaricons.find((icon) => icon.name === app.name);
          return {
            ...app,
            ActiveIcon: sidebarApp ? sidebarApp.ActiveIcon : app.ActiveIcon,
            inActiveIcon: sidebarApp ? sidebarApp.inActiveIcon : app.inActiveIcon,
          };
        })
      : sidebaricons;

  return (
    <div
      className={`z-50 flex w-10 items-center bottom-[5%] flex-col md:top-20 md:w-11 transition-all fixed duration-500 md:h-[80%] h-[70%] justify-center ${
        openMenu ? "fixed" : "fixed"
      }`}
    >
      {sideicon.map((app, index) => {
        const appTitle = langSelected.value === "ar" ? app?.titleAr : app?.titleEn;
        return (
          <div
            key={index}
            {...(app.status === "done"
              ? { "data-title": appTitle }
              : { "data-title": appTitle })}
            className={
              app.status === "done"
                ? "w-full relative tooltip m-auto cursor-pointer flex justify-center items-center text-white"
                : "w-full relative tooltip pt-5 m-auto cursor-pointer flex justify-center items-center text-white not-allow"
            }
          >
            <NavLink
              to={
                app.status === "done"
                  ? `${app.baseUrl}/${tenantUsername}/token/${_token}`
                  : "not-allowd"
              }
              className={
                app.status === "done"
                  ? "z-20 w-full flex items-center justify-between"
                  : "z-20 w-full flex items-center justify-between cursor-not-allowed"
              }
            >
              <img
                src={`${app.status === "done" ? app.ActiveIcon : app.inActiveIcon}`}
                alt={appTitle}
                className="w-7 m-auto hover:w-30 transition-all"
              />
            </NavLink>
            <span></span>
          </div>
        );
      })}
    </div>
  );
};

export default MenuSide;
