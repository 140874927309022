import React from 'react'
import { IoIosCloudDone } from "react-icons/io";
import { MdOutlineErrorOutline } from "react-icons/md";


const AlertMsg = ({children,type}) => {
    let customStyle = '';
    let customIcon = <IoIosCloudDone /> ;
    switch (type){
        case 'Error':
            customStyle = ' bg-red-100 border-red-200 text-red-700';
            customIcon = <MdOutlineErrorOutline size={20} /> ;
            break;
        default:
            customStyle = ' bg-green-100 border-green-200 text-green-700';
            customIcon = <IoIosCloudDone size={20} /> ;
    }

    return (
        <div className={`flex gap-1 w-max  items-center p-3 my-3 rounded-lg border-2 text-sm  ${customStyle}`}>
            {customIcon}
            {children}
        </div>
    )
}

export default AlertMsg