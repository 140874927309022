import React, { useState } from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";

const NotificationBarItem = ({children,setShow}) => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) return null;
  
    return (
      <div className="relative alert isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-1 sm:px-3.5 sm:before:flex-1">
        <div
          className="absolute top-1/2 left-[max(-7rem,calc(50%-52rem))] -z-10 -translate-y-1/2 blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-pink-400 to-purple-400 opacity-30"
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
          ></div>
        </div>
  
        <div
          className="absolute top-1/2 left-[max(45rem,calc(50%+8rem))] -z-10 -translate-y-1/2 blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-pink-400 to-purple-400 opacity-30"
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
          ></div>
        </div>
  
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-sm text-violet-600">
            <strong className="font-semibold text-violet-800"></strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline-block w-2 h-2 fill-current" aria-hidden="true">
              <circle cx="1" cy="1" r="1" />
            </svg>
           {children}
              </p>
        </div>
  
        <div className="flex flex-1 justify-end">
          <button
            type="button"
            className="-m-3 p-3 close-alert focus-visible:outline-offset-[-4px]"
            aria-label="إغلاق التنبيه"
            onClick={() => setIsVisible(false)}
          >
            <svg className="w-5 h-5 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
            </svg>
          </button>
        </div>
      </div>
    );
}

export default NotificationBarItem