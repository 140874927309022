import React from 'react'
import { APPS } from '../../constants/AbsatApps.js'
import AbsatAppsListItem from './AbsatAppsListItem.jsx'
import { constAPPS } from '../../constants/constIcons.js'

export const AbsatAppsList = ({ tenant }) => {
    const custApps = (tenant.apps && tenant.apps.length) > 0 ? tenant.apps : APPS;
    
    const _user = JSON.parse(localStorage.getItem('user'));

    const updatedApps = custApps.map(app => {
        const customIcon = constAPPS.find(icon => icon.name === app.name)?.icon;
        return {
            ...app,
            icon: customIcon || app.icon
        };
    });

    return (
        <div className="grid grid-cols-1 gap-3 w-[95%] items-center justify-center 
             sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5">
            {
                updatedApps.map(app => (
                    <AbsatAppsListItem key={app.name} user={_user} app={app} />
                ))
            }
        </div>
    )
}
