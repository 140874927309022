import React from 'react'
import { Link, useParams } from 'react-router-dom'
import App from '../../App';
import { useLang } from '../../hooks';

const AbsatAppsListItem = ({app,user}) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = (today.getMonth() + 1).toString().padStart(2, "0"); // Months start at 0!
    let dd = today.getDate().toString().padStart(2, "0");
    const {langSelected } = useLang();
    const _params = useParams();
    const _tenant = JSON.parse(localStorage.getItem('tenant'))?.username;
    const _token = localStorage.getItem('token');
    const _urlNavigate = app.status ==='done' ? app.name === "financial" ?  `${app.baseUrl+yyyy+mm+dd}df86d49a735f26a6022893c27cd834e9` : `${app.baseUrl}/${_tenant}/token/${_token}` : '#';
        const appTitle = langSelected.value==="ar" ? app?.titleAr : app?.titleEn
    return (
        <>
        { 
            user?.my_apps && user?.my_apps?.length > 0  ? ( user?.my_apps?.includes(app?._id) ?
                <Link to={`${_urlNavigate}`}>
                  <div className={app.status==="done"?"category py-5 hover:bg-violet-50  h-[110px]  transition-[0.7s] w-[100%] border border-[#B8AAEB] rounded-md flex flex-col justify-center items-center text-center gap-1":"category py-5 hover:cursor-not-allowed  h-[110px]  transition-[0.7s] w-[100%] border border-[#B8AAEB] rounded-md flex flex-col justify-center items-center text-center gap-1"}>
                  <img src={`/assets/icons/${app?.icon}`} className={` max-w-14  `} />
                  <h3 className="font-bold text-[17px] text-[#251658]">{appTitle}</h3>
                            </div>
                </Link> : <></> ) :
                <Link to={`${_urlNavigate}`}>
                  <div className={app.status==="done"?"category py-5 hover:bg-violet-50  h-[110px]  transition-[0.7s] w-[100%] border border-[#B8AAEB] rounded-md flex flex-col justify-center items-center text-center gap-1":"category py-5 hover:cursor-not-allowed  h-[110px]  transition-[0.7s] w-[100%] opacity-60 border border-[#c8c7c9] rounded-md flex flex-col justify-center items-center text-center gap-1"}>
                  <img src={`/assets/icons/${app?.icon}`} className={`  max-w-14  `} />
                  <h3 className="font-bold text-[17px] text-[#251658]">{appTitle}</h3>
                            </div>
                </Link>
        }
        </>
    )
}

export default AbsatAppsListItem