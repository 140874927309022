import React from 'react'
import {AiOutlineCopyrightCircle} from 'react-icons/ai'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <div className="static md:fixed bottom-1 w-[99%] items-center h-5 flex flex-wrap justify-center md:justify-between md:mt-3  px-5  text-xs text-slate-300  ">
            <div className='mx-12'>Version <span className='font-bold'>{process.env.REACT_APP_VERSION}</span></div>
            <div className='flex justify-center items-center gap-1 '> 
                <span>ALL CopyRight </span> 
                <AiOutlineCopyrightCircle/>
                <span> <span><Link target='_blank' to={`https://4sharks.org`}>4SHARKS</Link> </span>FOR SOFTWARE SOLUTIONS</span>
            </div>
        </div>
    )
}

export default Footer