import React, { useEffect, useState } from "react";
import { AbsatAppsList } from "../components/absat-apps/AbsatAppsList";
import { usePut, useTenant } from "../hooks";
import { useTranslation } from "react-i18next";
import MwInputFile from "../components/ui/MwInputFile";
import { MwSpinner } from "../components/ui";
import useUploadFile from "../hooks/useUploadFile";
import AlertMsg from "../components/ui/AlertMsg";

const DashBoard = () => {
  const { tenant } = useTenant();
  const [urlLogo, setUrlLogo] = useState(
    localStorage.getItem("companyLogo") ||
      tenant?.companies?.[0].logo ||
      "1.png"
  );

  useEffect(() => {
    const storedLogo = localStorage.getItem("companyLogo");
    if (storedLogo) {
      setUrlLogo(storedLogo);
    } else if (tenant?.companies?.[0]?.logo) {
      const newLogo = tenant.companies[0].logo;
      setUrlLogo(newLogo);
      localStorage.setItem("companyLogo", newLogo);
    }
  }, [tenant]);

  const { t } = useTranslation("global");
  const { handleUpload, location, loading, error , setError } = useUploadFile(
    [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/webp",
      "image/gif",
      "image/svg+xml",
      "image/bmp",
    ],
    "upload-path"
  );

  const { putData } = usePut();
  const info = localStorage.getItem("tenant");
  const CompanyData = info ? JSON.parse(info) : null;
  const companyid = CompanyData?.companies?.[0]?._id || null;
  const companyName =
    CompanyData?.companies?.[0]?.name?.[0]?.text || "غير متوفر";

  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const updateFile = (file) => {
    handleUpload(file);
    setSelectedFile(file);
  };

  useEffect(() => {
    if (!location || !tenant?._id || !selectedFile || !companyid) return;

    putData(`https://api.absat.org/auth/tenants/companies/${tenant._id}`, {
      companyId: companyid,
      companyData: { logo: location },
    }).then(() => {
      localStorage.setItem("companyLogo", location);
      setUrlLogo(location);
    });
  }, [location, selectedFile, tenant, companyid, putData]);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      {error && showAlert && (
        <div className="alert absolute  z-30">
        <AlertMsg children={t("Dashboard.uplaodFileerror")} type="Error" />
        </div>
      )}
      {loading ? (
        <div className="h-[81vh] flex justify-center items-center">
          <MwSpinner />
        </div>
      ) : (
        <div className="h-[81vh] flex flex-col mb-5 flex-grow justify-evenly relative items-center overflow-scroll">
          <div className="my-3 mb-0 pb-3">
            <div className="z-20 flex justify-center relative flex-col md:flex-col items-center">
              <img
                src={urlLogo}
                className="max-w-[250px] max-h-[120px] w-full h-full object-contain cursor-pointer"
                alt="Company Logo"
                onClick={() => document.getElementById("fileUpload").click()}
              />
              <div className="info md:flex flex-col justify-center items-start gap-0 p-4 m-auto">
              </div>
              <div className="inp opacity-0 hidden">
                <MwInputFile
                  id="fileUpload"
                  className="absolute opacity-0 w-full h-full cursor-pointer"
                  onChangeHandler={updateFile}
                />
              </div>
            </div>
          </div>
          <div className="apps w-[100%] gap-2 flex flex-col justify-center items-center">
            <div className="z-30 text-center font-bold text-[23px] text-[#251658]">
              تطبيقات <span className="theme-color">أبسط</span> أعمال
            </div>
            <div className="z-30 text-center mb-1 text-[#251658]">
              نظام إدارة الاعمال بسطناها عليك في كل شي
            </div>
            <AbsatAppsList tenant={tenant} />
          </div>
        </div>
      )}
    </>
  );
};

export default DashBoard;
