import { React, useEffect, useState, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";

const BasicSelector = ({
  listItem = [],
  children,
  border = true,
  onSelected,
  label,
  defaultValue,
  withArrow = true,
  withChangeLabel = true,
  size = "sm",
  className,
}) => {
  const [listLabel, setListLabel] = useState(label);
  const [openList, setOpenList] = useState(false);
  const dropdownRef = useRef(null);

  const customStyle = border
    ? "bg-slate-50 border rounded px-3 py-1 shadow-sm"
    : "px-3 py-1";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`relative flex flex-col px-3 ${className}`}
    >
      <div
        onClick={() => setOpenList(!openList)}
        className={`cursor-pointer text-xs flex items-center justify-between whitespace-nowrap transition-all hover:text-gray-200 gap-2 ${
          size === "lg" ? "h-10 text-sm" : "text-[15px]"
        } ${customStyle}`}
      >
        {children} {label && listLabel}
        {withArrow && <BiChevronDown />}
      </div>

      {listItem.length > 0 && (
        <ul
          className={`absolute w-full text-xs rounded-md top-full mt-1 z-30 bg-white shadow-lg border transition-all transform ${
            openList
              ? "scale-100 opacity-100"
              : "scale-95 opacity-0 pointer-events-none"
          }`}
        >
          {listItem.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                onSelected && onSelected(item);
                setListLabel(item.label); 
                setOpenList(false);
              }}
              className={`cursor-pointer text-md p-3 text-[#251658] hover:bg-gray-100 transition ${
                index !== listItem.length - 1 && "border-b"
              }`}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BasicSelector;
